import * as React from 'react';
import '../styles/grid.css';
import '../styles/styles.css';
import Layout from '../components/Layout';

export default function PrivacyPolicy (): React.ReactElement {

  return (
    <Layout>
      <div className="container wrapperContainer">
        <div>
          <div className="col-12">
            <h3>Datenschutzerklärung</h3>
            <p>Stand: Dezember 2023</p>
            <h4>Einleitung, Geltung, Verantwortlicher</h4>
            <p> Die vorliegenden Datenschutzinformationen gelten für die Verarbeitung personenbezogener Daten bei der Nutzung der Webseite https://www.triceonic.com und weiterer Dienste der Triceonic GmbH</p>
            <p>Verantwortlicher im Sinne des Art 4 Z 7 DSGVO ist die Triceonic GmbH, FN 563220s, c/o startup300 AG, Peter-Behrens-Platz 10, 4020 Linz, office(at)triceonic.com (nachfolgend kurz „TRICEONIC“).</p>
            <p>Der Schutz persönlicher Daten und die Einhaltung der maßgeblichen Datenschutzvorschriften - derzeit der Verordnung (EU) 2016/679 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung - „DSGVO“) und des Datenschutzgesetzes in der jeweils geltenden Fassung („DSG“) sowie der auf deren Grundlage erlassenen Rechtsakte - haben bei TRICEONIC höchste Priorität. Die vorliegenden Datenschutzinformationen geben gemäß den Art 12 und 13 DSGVO einen Überblick darüber, welche personenbezogenen Daten bei der Nutzung der Webseite zu welchen Zwecken verarbeitet werden und wie TRICEONIC den Schutz dieser Daten gewährleistet.
            </p>
            <p>Diese Datenschutzinformationen können auf der Website https://www.triceonic.com („Website“) abgerufen, ausgedruckt, downgeloaded und auf einem Speichermedium gespeichert werden.</p>
            <p> Die in diesen Datenschutzinformationen verwendeten Begriffe verstehen sich im Sinne der Definitionen gemäß Art 4 DSGVO.</p>

            <h4> Erhebung und Verarbeitung personenbezogener Daten</h4>
            <p>Bei der Kontaktaufnahme und bei der Abwicklung von Vertragsverhältnissen mit TRICEONIC werden unter Umständen folgende Daten bei Kontaktaufnehmenden und Vertragspartnern erhoben und auf der Grundlage von Art 6 Abs 1 lit b) DSGVO zum Zwecke der Übermittlung zielgerichteter Angebote und der Bearbeitung von Anfragen sowie der Erfüllung und Abwicklung des Vertrages mit TRICEONIC verarbeitet: Stammdaten, wie Vor- und Nachname, Geschäftsbezeichnung, Geburtsdatum, Kontaktdaten, wie Rechnungs- und Lieferadresse (Straße, Postleitzahl, Staat), E-Mail-Adresse, Telefonnummer, Bestellungs- und Vertragsdaten, UID-Nummer, Rechnungs-, und Bank-/Konto-/Zahlungsdaten, wie Bankbezeichnung, IBAN und BIC, sowie erforderlichenfalls Korrespondenz-/Kommunikations-/Inhaltsdaten. Zur Beantwortung allfälliger Anfragen und Anschlussfragen werden die Daten von Kontaktaufnehmenden für die Dauer von 12 (zwölf) Monaten gespeichert und danach automatisch gelöscht, sofern kein Vertragsverhältnis zustande kommt. Die Daten von Vertragspartnern werden so lange verarbeitet und gespeichert, wie dies für die Erfüllung der Vertragsverhältnisse (einschließlich nachvertraglicher Pflichten) und aus gesetzlichen (insbesondere umsatzsteuerlichen) Gründen notwendig ist. Die Daten der betroffenen Personen werden vertraulich behandelt und - sofern dies zur Erfüllung vertraglicher oder vor- oder nachvertraglicher Pflichten erforderlich ist - auch an die mit der Abwicklung des Vertragsverhältnisses befassten Erfüllungsgehilfen (wie zB Subunternehmer, etc) weitergegeben.
            </p>
            <p>Beim Aufruf der Webseite erhebt und verarbeitet TRICEONIC automatisiert Daten technischer Natur, die als personenbezogen gelten könnten oder die zur Ermittlung betroffenen Personen oder personenbezogener Daten verwendet werden könnten, wie insbesondere Mess- und Zugriffsdaten sowie gegebenenfalls Daten durch Verwendung von Cookies und Local Storage. TRICEONIC verarbeitet diese technischen Daten aber nicht zum Zwecke der Identifizierung von Personen oder der Ermittlung anderer personenbezogener Daten, sondern ausschließlich zum Zwecke des Betriebes, der Sicherheit und der bedarfsgerechten Gestaltung, Adaptierung, Verbesserung, Wartung, Optimierung und Weiterentwicklung der Webseite sowie zur Fehlererkennung und -behebung, zur Aufrechterhaltung der Systemsicherheit sowie zum Zwecke der internen statistischen Auswertung, ohne dass dabei Rückschlüsse auf die betroffenen Personen gezogen werden, auf der Grundlage von Art 6 Abs 1 lit b) DSGVO zum Zwecke der Vertragsabwicklung bzw -erfüllung, der berechtigten Interessen von TRICEONIC gemäß Art 6 Abs 1 lit f) DSGVO sowie erforderlichenfalls auf Grundlage einer Einwilligung gemäß Art 6 Abs 1 lit a) DSGVO. Es findet diesbezüglich auch kein Profiling statt. Auch diese Daten werden für die Dauer von 12 (zwölf) Monaten gespeichert und danach automatisch gelöscht, sofern kein Vertragsverhältnis zustande kommt.
            </p>
            <p>TRICEONIC verarbeitet - mit Ausnahme automatisch erhobener technischer Zugriffsdaten und durch notwendige Cookies erhobene Daten über die Website - keine Daten, die nicht bei den betroffenen Personen selbst erhoben werden.</p>

            <h4>Verarbeitung von Daten zum Zwecke der Direktwerbung </h4>
            <p>Erhält TRICEONIC im Zusammenhang mit der Erbringung einer Dienstleistung die E-Mail-Adresse von betroffenen Personen, ist sie auf Grund ihres berechtigten Interesses gemäß Art 6 Abs 1 lit f) DSGVO berechtigt, per E-Mail Direktwerbung in Form von Informationen und Zusendungen für eigene oder ähnliche Produkte zu übermitteln. Die betroffenen Personen haben das Recht, jederzeit (insbesondere auch bei der Übertragung der E-Mails) Widerspruch gegen die Verarbeitung sie betreffender Daten zum Zwecke derartiger Werbung einzulegen.</p>

            <h4>Verarbeitung von Daten zum Zwecke des Versandes eines E-Mail-Newsletters </h4>
            <p>Sofern TRICEONIC E-Mail-Newsletter versendet, erhebt und verarbeitet sie die E-Mail-Adressen der Abonnenten der E-Mail-Newsletter nur auf Grundlage einer Einwilligung gemäß Art 6 Abs 1 lit a) DSGVO, welche über ein sogenanntes „Double-Opt-In-Verfahren“ eingeholt wird, und speichert die E-Mail-Adressen der Abonnenten so lange, bis sich diese hiervon abgemeldet, respektive ihre Einwilligung widerrufen haben.</p>

            <h4>Dauer der Datenverarbeitung, Aufbewahrungs- und Speicherdauer </h4>
            <p>TRICEONIC verarbeitet und speichert Daten nicht dauerhaft, sondern nur in Übereinstimmung mit den in den jeweils geltenden Rechtsvorschriften vorgeschriebenen Fristen, jedenfalls aber so lange, als dies für jene Zwecke erforderlich ist, für welche die Daten erhoben wurden. TRICEONIC speichert Daten in einer Form, welche die Identifizierung der betroffenen Personen nur so lange ermöglicht, wie es für die Zwecke, für die sie verarbeitet werden, erforderlich ist.
            </p>
            <p>Werden Daten nur auf der Grundlage einer Einwilligung verarbeitet, werden diese Daten in-Folge eines Widerrufes der Einwilligung seitens der betroffenen Personen gemäß Art 7 Abs 3 DSGVO unverzüglich gelöscht und nicht (mehr) weiterverarbeitet. Gleiches gilt im Falle eines berechtigten Widerspruches gemäß Art 21 DSGVO, wenn Daten nur auf der Grundlage eines berechtigten Interesses gemäß Art 6 Abs 1 lit f) DSGVO verarbeitet werden. </p>
            <h4>Erhebung von Daten durch Cookies und Local Storage </h4>
            <p>TRICEONIC setzt aktuell keine Local-Storage-Funktionen (auch „Lokaler Speicher“ genannt) oder Cookies ein. Der Einsatz von Cookies und/oder Local Storage bleibt aber vorbehalten. Bei Cookies und Local-Storages handelt es sich um Dateien, die beim Aufruf der Software lokal im Zwischenspeicher des Internetbrowsers der betroffenen Personen gespeichert wer-den und die insbesondere dazu dienen, um zusätzliche Funktionen anzubieten, um die Soft-ware durch Wiedererkennung des zugreifenden Internetbrowsers und durch Speicherung temporärer Dateien benutzerfreundlicher, effektiver und sicherer zu machen sowie - sofern Webanalysetools verwendet werden - um eine (anonymisierte) Analyse der Benutzung zu ermöglichen. </p>
            <p>Cookies und Local Storages, die für die Benutzung der Website unbedingt erforderlich sind, werden auf Grundlage der berechtigten Interessen von TRICEONIC gemäß Art 6 Abs 1 lit f) DSGVO am Betrieb, an der Sicherheit und an der Optimierung eingesetzt. Allfällige weitere Cookies und Local Storages werden nur auf der Grundlage einer Einwilligung gemäß Art 6 Abs 1 lit a) DSGVO, welche die betroffenen Personen nach Aufruf durch aktives Anklicken einer Tick-Box erteilen können, verarbeitet. Die Personen haben jederzeit die Möglichkeit, ihre Einwilligung zu widerrufen, indem sie in den Einstellungen ihres Internetbrowsers Cookies bzw Local Storages deaktivieren und/oder löschen sowie festlegen, wie lange sie gespeichert und wann sie gelöscht werden. Die Vorgangsweise dazu ist abhängig von dem von den betroffenen Personen verwendeten Internetbrowser. Das Nichtakzeptieren und die Deaktivierung von Cookies bzw von Local Storages können jedoch dazu führen, dass gewisse Funktionen und/oder Inhalte der Software nicht oder nicht wie erwartet funktionieren. </p>
            <p>Session Cookies bzw Session Storages werden temporär für die Dauer des Zugriffes durch die Personen gespeichert und nach dem Schließen des Browsers gelöscht; dauerhafte Cookies bzw Storages bleiben so lange auf dem Endgerät der Personen gespeichert, bis diese sie von ihrem Browser entfernen. </p>

            <h4>Verwendung von Diensten Dritter </h4>
            <p>Für den Zugriff auf Drittsysteme verwendet TRICEONIC Dienste von Drittanbietern, wie Amazon Web Services („AWS“), insbesondere AWS CloudFrontEnd und AWS CloudWatch.</p>
            <p>Die Verarbeitung der Daten durch die Drittanbieter erfolgt im Rahmen der jeweiligen Datenschutzbestimmungen der Drittanbieter. TRICEONIC hat als Betreiber der Software keinen Einfluss auf an die Drittanbieter übermittelten Daten sowie deren Verarbeitung. </p>

            <h4>Datenverarbeitung im Auftrag von TRICEONIC </h4>
            <p>Erfolgt eine Verarbeitung von Daten im Auftrag von TRICEONIC, so arbeitet diese nur mit Auftragsverarbeitern im Sinne des Art 4 Z 8 DSGVO, die hinreichende Garantien dafür bieten, dass geeignete technische und organisatorische Maßnahmen so durchgeführt werden, dass die Verarbeitung im Einklang mit den bestehenden Rechtsvorschriften erfolgt und der Schutz der Rechte der Personen gewährleistet ist. Zu diesem Zweck schließt TRICEONIC mit ihren Auftragsverarbeitern entsprechende Verträge ab, welche den Anforderungen des Art 28 DSGVO entsprechen und beachtet bei Auftragsverarbeitern mit Sitz in Drittstaaten die Art 44 ff DSGVO.</p>
            <p>Auftragsverarbeiter von TRICEONIC beim Betrieb der Software sind aktuell: </p>
            <p>- Amazon Web Services (AWS) <br />
              - Microsoft MS365<br />
              - Microsoft Onedrive / Sharepoint</p>
            <p>Gemäß den jeweiligen Datenschutzbestimmungen der genutzten Dienste laufen diese auf Servern in der EU und sind alle Daten auf Servern in der EU
              gespeichert.</p>

            <h4>Nutzung von LinkedIn </h4>
            <p>TRICEONIC nutzt auf seinen Webseiten Links zu Profilen auf LinkedIn (LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA). Bei Aufruf eines verlinkten LinkedIn Inhaltes durch Webseitenbesucher und gleichzeitig bestehender Anmeldung bei LinkedIn mit einem Benutzerkonto, ist es LinkedIn möglich, den Besuch der Webseite von TRICEONIC einem Benutzerkonto zuzuordnen.</p>
            <p>TRICEONIC weist ausdrücklich darauf hin, dass TRICEONIC als Anbieter der Seiten, keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch LinkedIn hat. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von LinkedIn unter:</p>
            <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank">https://www.linkedin.com/legal/privacy-policy</a>

            <h4>Sicherheit der Datenverarbeitung</h4>
            <p>TRICEONIC trifft unter Berücksichtigung der Kriterien des Art 32 DSGVO angemessene und geeignete technische und organisatorische Maßnahmen zur Sicherheit der Daten sowie der Datenverarbeitung und sorgt dafür, dass die Daten vor unbefugter oder unrechtmäßiger Verarbeitung und vor Verlust, Beschädigung und Veränderung geschützt werden. </p>
            <h4>Rechte der betroffenen Personen </h4>
            <p>TRICEONIC wahrt die Rechte der betroffenen Personen nach Maßgabe der jeweils geltenden Rechtsvorschriften. Nach derzeitiger Rechtslage stehen den betroffenen Personen die nach-stehend (abstrakt) angeführten Rechte zu. Betroffene Personen können ihre Rechte durch Übermittlung eines entsprechend konkretisierten Ersuchens - empfohlener Weise in Textform (zB Brief oder E-Mail) - an TRICEONIC (Kontaktdaten siehe Punkt 1.1.) geltend machen. Sofern die geltenden Rechtsvorschriften Fristen für die Erledigung des Ersuchens vorsehen, wird TRICEONIC diese einhalten. </p>
            <p><u>Recht auf Geheimhaltung:</u> TRICEONIC wahrt das Grundrecht betroffener Personen auf Datenschutz nach Maßgabe des § 1 Abs 1 DSG und das Recht auf Datengeheimnis nach Maßgabe des § 6 DSG.</p>
            <p><u>Recht auf Auskunft und Information: </u>Unter den Voraussetzungen und nach Maßgabe der Art 13 bis 15 DSGVO haben die be-troffenen Personen das Recht auf Auskunft und Information über die Verarbeitung ihrer Da-ten durch TRICEONIC sowie über ihre Rechte.</p>
            <p><u>Recht auf Berichtigung und Vervollständigung: </u>Unter den Voraussetzungen und nach Maßgabe des Art 16 DSGVO haben die betroffenen Personen das Recht auf Berichtigung unrichtiger und Vervollständigung unvollständiger sie betreffender Daten.</p>
            <p><u>Recht auf Löschung:</u> Unter den Voraussetzungen und nach Maßgabe des Art 17 DSGVO haben die betroffenen Personen das Recht, die unverzügliche Löschung sie betreffender Daten zu verlangen.</p>
            <p><u>Recht auf Einschränkung der Verarbeitung:</u> Unter den Voraussetzungen und nach Maßgabe des Art 18 DSGVO haben die betroffenen Personen das Recht, die Einschränkung der Verarbeitung ihrer Daten zu verlangen.</p>
            <p><u>Recht auf Datenübertragbarkeit: </u>Unter den Voraussetzungen und nach Maßgabe des Art 20 DSGVO haben die betroffenen Personen das Recht, sie betreffende Daten, die sie TRICEONIC bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und diese Daten einem anderen Verantwortlichen zu übermitteln oder von TRICEONIC zu verlangen, die von ihr verarbeiteten Daten direkt an einen anderen Verantwortlichen zu übermitteln, soweit dies technisch machbar ist und sofern dadurch nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.</p>
            <p><u>Widerspruchsrecht:</u> Unter den Voraussetzungen und nach Maßgabe des Art 21 DSGVO haben die betroffenen Personen das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender Daten, die aufgrund von Art 6 Abs 1 lit e) oder f) DSGVO erfolgt, Widerspruch einzulegen. Im Falle eines berechtigten Widerspruchs verarbeitet TRICEONIC die vom Widerspruch betroffenen Daten dieser Personen nicht mehr, es sei denn, sie kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, welche die Interessen, Rechte und Freiheiten der betroffenen Personen überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Wider-spricht der Personen der Verarbeitung für Zwecke der Direktwerbung, so werden ihre Daten nicht mehr für diese Zwecke verarbeitet.</p>
            <p><u>Recht, nicht einer automatisierten Entscheidung unterworfen zu werden: </u>Unter den Voraussetzungen und nach Maßgabe des Art 22 DSGVO haben die betroffenen Personen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung - ein-schließlich Profiling - beruhenden Entscheidung unterworfen, die ihr gegenüber rechtliche Wirkung entfalten oder sie in ähnlicher Weise erheblich beeinträchtigen.</p>
            <p><u>Widerrufsrecht:</u> Gemäß Art 7 Abs 3 DSGVO haben die betroffenen Personen das Recht, ihre Einwilligung zur Verarbeitung sie betreffender Daten jederzeit zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.</p>
            <p><u>Recht auf Beschwerde:</u> Gemäß Art 77 DSGVO iVm § 24 DSG haben die betroffenen Personen unbeschadet eines an-derweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei der jeweils zuständigen Aufsichtsbehörde (Datenschutzbehörde).</p>
            <p><u> Recht auf gerichtlichen Rechtsbehelf:</u> Gemäß Art 79 DSGVO iVm § 27 DSG haben die betroffenen Personen unbeschadet eines an-derweitigen verwaltungsrechtlichen oder außergerichtlichen Rechtsbehelfs das Recht auf ei-nen wirksamen gerichtlichen Rechtsbehelf gegen einen sie betreffenden rechtsverbindlichen Beschluss der Aufsichtsbehörde (Recht auf Beschwerde an das Bundesverwaltungsgericht).</p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
